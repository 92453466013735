.required {
  &:after {
    content: "*";
    color: rgba(220, 53, 69, 1);
    margin-inline-start: 4px;
    font-weight: 700;
  }
}

.submit-button {
  padding: 5px 30px;
  border-radius: 0.6875rem;
  text-align: center;
  font-family: "Noto Kufi Arabic";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #d24359;
  color: white;
  height: 60px;
  &:hover {
    background: #d24359 !important;
    color: white !important;
  }
}
