/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("./assets/global-styles/global.scss");
@import "bootstrap/dist/css/bootstrap.css";
// @import "primeng/resources/themes/lara-light-blue/theme.css";
// @import "primeng/resources/primeng.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Noto+Kufi+Arabic:wght@100..900&display=swap");
@import url("./assets/global-styles/global.scss");
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import url("./assets/style/_form.scss");
// import Quill styles in styles.scss
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
body,
html {
  direction: rtl !important;
}

body {
  &::-webkit-scrollbar {
    width: 0.4em;
    height: 0.2em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d24359;
    outline: 1px solid #d24359;
  }
}

* {
  direction: inherit !important;
  font-family: "Noto Kufi Arabic";
}

//tabs genric style

.p-tabview .p-tabview-nav {
  display: flex;
  justify-content: space-around;
  font-family: "Noto Kufi Arabic";
  font-size: 18px;
  font-style: normal;

  color: #acacac;
  border: 0;
  border-bottom: 1px solid #ebebeb;
  border: none;
}

.p-tabview .p-tabview-nav-content {
  border-bottom: 1px solid #ebebeb;
}

.p-tabview-title {
  border-color: #acacac;
  color: #acacac;
  border: none;
}

// .p-ripple{
//     display: none;
// }

// ***** Start En Style *****
html[dir="ltr"] {
  body {
    direction: ltr !important;
  }

  .text-start {
    text-align: right !important;
  }
  .suggestionForm {
    .formGroup {
      p {
        margin-left: unset;
        margin-right: 88px;
        @media (max-width: 767px) {
          margin-right: 0;
        }
      }
    }
    .text-start {
      text-align: right !important;
    }
  }
  .formRadioBtn {
    .singleOption {
      margin-right: unset;
      margin-left: 44px;
      &:first-of-type {
        margin-left: 0;
      }
    }
    label {
      margin-right: unset;
      margin-left: 10px;
    }
  }
  .phoneNo {
    input:placeholder-shown{
      direction: rtl !important;
      text-align: end;
    }
  }
  app-form-checkbox {
    margin-left: unset;
    margin-right: 66px;
    &:last-of-type {
      margin-right: 0;
    }
    label {
      margin-right: unset;
      margin-left: 16px;
    }
  }
  file-upload {
    .upload-text {
      img {
        margin-left: unset !important;
        margin-right: 10px;
      }
    }
  }

  .complaintForm {
    .formGroup {
      p {
        margin-left: unset;
        margin-right: 88px;
        @media (max-width: 767px) {
          margin-right: 0;
        }
      }
    }
    .text-start {
      text-align: right !important;
    }
  }
  p-breadcrumb .p-breadcrumb .p-menuitem-separator {
    transform: rotate(0deg) !important;
  }

  .event-card__arrow,
  .arrow-left {
    transform: rotate(180deg);
  }

  .p-toast-top-right {
    right: unset;
    left: 20px;
  }
}
// ***** End En Style *****

html[dir="rtl"] {
  p-calendar {
    .p-datepicker-next-icon,
    .p-datepicker-prev-icon {
      transform: rotate(180deg);
    }

    .p-datepicker {
      // left: -85px !important;
      left: 0 !important;
    }
  }

  .p-paginator-element.p-paginator-first,
  .p-paginator-element.p-paginator-last,
  .p-paginator-element.p-paginator-prev,
  .p-paginator-element.p-paginator-next {
    transform: rotate(180deg);
  }

  .dir-ltr {
    direction: ltr !important;
    text-align: end;
  }

  .phoneNo {
    input {
      direction: ltr !important;
      text-align: end;
    }
    input:placeholder-shown{
      direction: rtl !important;
      text-align: start;
    }
  }

  .dropdown-menu {
    text-align: start;
  }
}

.p-highlight {
  border-bottom: 1px solid #d24359;
}

.p-element .p-ripple .p-tabview-nav-link,
a {
  text-decoration: none;
}

.p-element .p-ripple .p-tabview-nav-link,
a {
  text-decoration: none;
  border: 0;
}

// .p-ripple {
//   display: none;
// }

.p-toast-message.p-toast-message-success {
  background: #eaf7f0;
  border: 0.4px solid #0ecb78;
  // color: #1ea97c;
  border-radius: 10px;
  box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.05);
}
.p-toast-summary {
  font-size: 18px;
  line-height: 21.6px;
  color: #22282b;
}

.p-toast-detail {
  color: #000000;
  margin-top: 11px;
  font-size: 14px;
  font-weight: 400;
  line-height: 25.2px;
}
.p-toast-message-success {
  .p-toast-message-icon {
    .p-icon-wrapper {
      background: #21cb7a;
      border-radius: 50%;
      display: flex;
      width: 38px;
      height: 38px;
      align-items: center;
      justify-content: center;
      svg {
        color: #ffffff;
        width: 15px;
        height: 15px;
      }
    }
  }
}
.p-toast-icon-close {
  svg {
    color: #26314b;
  }
}
.p-toast {
  width: 30rem;
}
.p-toast-message.p-toast-message-error {
  background: #fff7f7;
  border: 0.4px solid #d24359;
  border-radius: 10px;
  box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.05);
}
.p-toast-message-icon {
  margin-top: 11px;
  .p-icon-wrapper {
    margin-left: 30px;
  }
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #d24359;
}
.english {
  direction: ltr !important;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}

.printedPage {
  min-width: 794px;
  max-width: 794px;
  h5:nth-of-type(3) {
    margin-top: 100px;
  }
  .d-flex.flex-column.gap-4.details-container {
    .col-12 {
      width: 50% !important;
    }
  }
}

.btn-redBg {
  border-radius: 11px;
  background: #d24359;
  color: #fff;
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: "Noto Kufi Arabic";
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 20px */
  padding: 10px 20px;
  &:hover {
    background-color: #d24359;
    color: #fff;
  }
}

.form-check-input:checked {
  background-color: #d24359;
  border-color: #d24359;
}

.cursor-pointer {
  cursor: pointer;
}

.gradient-top {
  background: linear-gradient(180deg, rgba(243, 245, 246, 0) 0%, #f3f5f6 100%);
  height: 284px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}
.gap-5 {
  @media (min-width: 1200px) and (max-width: 1400px) {
    gap: 2rem !important;
  }
}

input {
	&:-internal-autofill-selected {
		background-color: transparent !important;
	}
}